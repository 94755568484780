import { Box, Container, Hidden } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Layout } from 'src/components';
import UseInstallments from 'src/components/Landing/UseInstallments';
import LinearCTAButton from 'src/components/LinearCTAButton';
import JulyAppDownload from 'src/components/Merchant/Campaign/2022AAA_202211/JulyAppDownload';
import SectionCampaignFooter from 'src/components/Merchant/Campaign/TravelsSummerVol1/SectionCampaignFooter';
import { SITE_METADATA } from 'src/constants';
import PaidyLogoImg from 'src/images/merchant/campaign/202304_6pay/paidy-logo.png';
import BannerImg from 'src/images/campaign/20231120_lottery/banner.png';
import BannerSpImg from 'src/images/campaign/20231120_lottery/banner-sp.png';
import SixPayUpsellImg from 'src/images/campaign/202307_10MM_merchant_vol1/6pay_upsell.png';
import QrAndBadgesSwitch from 'src/components/QrAndBadgesSwitch';
import CartImg from 'src/images/6pay/cart.png';
import PayStep3Img from 'src/images/6pay/pay-step-3.png';
import PayStep4Img from 'src/images/6pay/pay-step-4-12pay.png';
import SectionMerchantGrid from 'src/components/Campaign/20231120_lottery/SectionMerchantGrid';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import classNames from 'classnames';
import { Link } from 'gatsby';
import throttle from 'lodash.throttle';
import Slider from 'react-slick';
import styles from 'src/styles/pages/202310_n_pay.module.scss';
import Lottery202303PaymentSteps from 'src/components/Merchant/Campaign/Lottery202303/Lottery202303PaymentSteps';

const SEOProps = {
  title: SITE_METADATA.campaign_20231120_lottery.title,
  description: SITE_METADATA.campaign_20231120_lottery.description,
};

const LOGOS = [...Array(11).keys()].map((key) => ({
  key,
  // eslint-disable-next-line import/no-dynamic-require, security/detect-non-literal-require
  src: require(`src/images/campaign/20231120_lottery/merchant-logos-header/merchant-${key}.png`),
}));

const NAVS = ['キャンペーン概要', '対象ショップ', 'ペイディの使い方'];

const TERMS = [
  {
    title: 'キャンペーン期間',
    description: '2023年11月20日 (月) 0:00～12月31日 (日) 23:59',
  },
  {
    title: 'キャンペーン概要・特典',
    description: (
      <>
        キャンペーン期間中に対象ショップの決済画面でペイディを利用すると、抽選で3人に1人キャッシュバックが当たります。
        <br />
        1等全額、2等10%、3等3％キャッシュバック。※最大10万円
        <br />
        <br />
        <small>
          キャンペーン終了後、2024年1月31日までに特典分がキャッシュバックされる予定です。キャッシュバックはペイディアプリおよびMyPaidyに反映されます。決済画面には反映されません。
        </small>
      </>
    ),
  },
  {
    title: 'キャンペーン対象',
    description:
      '対象ショップの決済画面で「あと払い（ペイディ）」をご利用のお客様',
  },
  {
    title: 'ご注意事項',
    description: (
      <ul>
        <li>
          同期間に開催中の他のキャンペーンと併せて月間当選上限額は10万円になります。
        </li>
        <li>
          キャッシュバックは、決済画面でペイディを選択した場合のみ対象です。ペイディカードの利用は対象外となります。
        </li>
        <li>
          キャンペーン終了後2024年1月31日までに特典分がキャッシュバックされる予定です。また予定日より遅れることもありますので、予めご了承ください。
        </li>
        <li>
          キャッシュバック付与のタイミングで、ペイディの利用が無い場合、付与日以降でペイディをご利用いただいた際に、該当キャッシュバックが付与されます。あらかじめご了承ください。
        </li>
        <li>
          株式会社Paidyは本キャンペーンを、キャンペーン期間であっても予告なく変更または終了する場合がございます。
        </li>
        <li>
          不正と判断された場合やキャンセルがあった場合は、キャッシュバックが取り消される場合がございます。
        </li>
        <li>
          その他条件はペイディ
          <OutboundLink
            href="https://terms.paidy.com/cashback/"
            target="_blank"
            rel="noopener noreferrer"
          >
            キャッシュバック及びクーポン利用規約
          </OutboundLink>
          に準じます。
        </li>
        <li>
          ペイディに関するお問い合わせは
          <OutboundLink
            href="https://cs.paidy.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            ペイディヘルプページ
          </OutboundLink>
          へご連絡ください。
        </li>
      </ul>
    ),
  },
];

const SIXPAY = [
  {
    ImgComponent: () => <QrAndBadgesSwitch />,
    figCaption: 'ペイディアプリから本人確認する。',
  },
  {
    ImgComponent: () => (
      <img alt="cart" src={CartImg} width={184} height={184} />
    ),
    figCaption: 'ショップで欲しい商品をカートに入れる。',
  },
  {
    ImgComponent: () => (
      <img alt="pay-step-3" src={PayStep3Img} width={184} height={184} />
    ),
    figCaption:
      'お支払い方法で「あと払い（ペイディ）」を選択して、メールアドレスと携帯電話番号を入力する。',
  },
  {
    ImgComponent: () => (
      <img alt="pay-step-4" src={PayStep4Img} width={184} height={184} />
    ),
    figCaption: 'ご希望のお支払い回数を選んで完了。',
  },
];

const useScrollAndSetActiveSection = (navs = NAVS) => {
  const [activeSection, setActiveSection] = useState(navs[0]);

  const onScroll = useCallback(() => {
    if (!document) {
      return;
    }

    // eslint-disable-next-line no-restricted-syntax, no-plusplus
    for (let i = 0; i < navs.length; i++) {
      const nav = navs[i];
      const rect = document.getElementById(nav).getBoundingClientRect();
      const isOnScreen = rect.top <= 64 && rect.bottom >= 64;

      if (isOnScreen) {
        setActiveSection(nav);
      }
    }
  }, [navs]);

  useEffect(() => {
    const throttledScroll = throttle(onScroll, 200);

    if (window) {
      window.addEventListener('scroll', throttledScroll, true);
    }

    return () => {
      if (window) {
        window.removeEventListener('scroll', throttledScroll);
      }
    };
  }, [onScroll]);

  return activeSection;
};

const SLIDER_SETTINGS = {
  classNames: 'slider variable-width',
  dots: false,
  infinite: true,
  centerPadding: '2px',
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 2000,
  cssEase: 'linear',
  variableWidth: true,
};

export default function Campaign202310NPay() {
  const activeSection = useScrollAndSetActiveSection(NAVS);

  return (
    <Layout SEOProps={SEOProps} hasNoHeader hasNoFooter>
      <Box className={styles.page}>
        <header className={styles.sectionHeader}>
          <img alt="paidy-logo" width={114} height={45} src={PaidyLogoImg} />
        </header>

        <section className={styles.sectionBanner}>
          <Hidden xsDown>
            <img alt="banner" height={522} width={1440} src={BannerImg} />
          </Hidden>
          <Hidden smUp>
            <img alt="banner-sp" width="100%" src={BannerSpImg} />
          </Hidden>
        </section>

        <section className={styles.sectionMerchantLogos}>
          <Hidden xsDown>
            <p className={styles.title}>おすすめの対象ショップ</p>
            <Box className={styles.rows}>
              <Box
                display="flex"
                flex={1}
                justifyContent="center"
                flexWrap="wrap"
              >
                {LOGOS.slice(0, 5).map((logo) => (
                  <Box key={logo.key}>
                    <img
                      alt={logo.key}
                      src={logo.src}
                      width={152}
                      height={50}
                    />
                  </Box>
                ))}
              </Box>
              <Box
                display="flex"
                flex={1}
                justifyContent="center"
                marginTop={3}
                flexWrap="wrap"
              >
                {LOGOS.slice(5).map((logo) => (
                  <Box key={logo.key}>
                    <img
                      alt={logo.key}
                      src={logo.src}
                      width={152}
                      height={50}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </Hidden>

          <Hidden smUp>
            <Box width="100%" height={80}>
              <Slider {...SLIDER_SETTINGS}>
                {LOGOS.map((logo) => (
                  <div style={{ width: 140 }}>
                    <img
                      alt={logo.key}
                      src={logo.src}
                      width={140}
                      height={46}
                    />
                  </div>
                ))}
              </Slider>
            </Box>
          </Hidden>
          <LinearCTAButton
            text="すべての対象ショップを見る"
            to={`#${NAVS[1]}`}
            className={styles.button}
          />
        </section>

        <nav className={styles.sectionNav}>
          {NAVS.map((nav) => (
            <Box
              key={nav}
              className={classNames(styles.item, {
                [styles.active]: nav === activeSection,
              })}
            >
              <Link to={`#${nav}`}>{nav}</Link>
            </Box>
          ))}
        </nav>

        <section className={styles.sectionTerms} id={NAVS[0]}>
          <Container className={styles.container}>
            {TERMS.map((term) => (
              <Box key={term.title}>
                <p className={styles.title}>{term.title}</p>
                <p className={styles.description}>{term.description}</p>
                {term.notes && <p className={styles.notes}>{term.notes}</p>}
              </Box>
            ))}
          </Container>
        </section>

        <section id={NAVS[1]}>
          <SectionMerchantGrid />
        </section>

        <Lottery202303PaymentSteps
          title="ペイディの使い方はとってもかんたん"
          className={styles.sectionPaymentSteps}
          id={NAVS[2]}
        />

        <section className={styles.section6Pay}>
          <Box className={styles.top}>
            <>
              <Hidden xsDown>
                <img
                  alt="6pay"
                  width={367}
                  height={400}
                  src={SixPayUpsellImg}
                />
              </Hidden>
              <Hidden smUp>
                <img
                  alt="6pay"
                  width={247}
                  height={268}
                  src={SixPayUpsellImg}
                />
              </Hidden>
            </>
            <Box className={styles.texts}>
              <p className={styles.title}>
                分割手数料無料*
                <br />
                3・6・12回あと払いで、
                <br />
                かしこくお支払い。
              </p>
              <p className={styles.text}>
                ペイディアプリから本人確認をすると、3・6・12回あと払いが分割手数料無料*でご利用いただけます。あなたのライフスタイルにあわせて、お支払い回数を選べます。一括払いで購入後に、ペイディアプリから変更することも可能です。
              </p>
              <p className={styles.notes}>
                *口座振替・銀行振込のみ無料
                <br />
                ※3回あと払いは1回のお買い物につき3,000円以上、6回あと払いは1回のお買い物につき6,000円以上、12回あと払いは1回のお買い物につき12,000円以上のお支払いで利用可能です。
                <br />
                <br />
                ※選択できる支払い回数は加盟店により異なります。
                <br />
                <ul>
                  <li>
                    6回あと払いが使えるお店は
                    <OutboundLink
                      href="https://cs-support.paidy.com/support/solutions/articles/150000040642-%EF%BC%96%E5%9B%9E%E3%81%82%E3%81%A8%E6%89%95%E3%81%84%E3%81%8C%E5%88%A9%E7%94%A8%E5%8F%AF%E8%83%BD%E3%81%AA%E5%BA%97%E8%88%97%E3%82%92%E7%9F%A5%E3%82%8A%E3%81%9F%E3%81%84"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      こちら
                    </OutboundLink>
                  </li>
                  <li>
                    12回あと払いが使えるお店は
                    <OutboundLink
                      href="https://cs-support.paidy.com/support/solutions/articles/150000070979-%EF%BC%91%EF%BC%92%E5%9B%9E%E3%81%82%E3%81%A8%E6%89%95%E3%81%84%E3%81%8C%E5%88%A9%E7%94%A8%E5%8F%AF%E8%83%BD%E3%81%AA%E5%BA%97%E8%88%97%E3%82%92%E7%9F%A5%E3%82%8A%E3%81%9F%E3%81%84"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      こちら
                    </OutboundLink>
                  </li>
                </ul>
              </p>
            </Box>
          </Box>
          <UseInstallments
            title="3・6・12回あと払いのご利用方法"
            data={SIXPAY}
          />
        </section>

        <JulyAppDownload />

        <SectionCampaignFooter isThemeBlack />
      </Box>
    </Layout>
  );
}
