import { createArray } from 'src/utils';

export const MERCHANTS = [
  {
    id: 0,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-0.png'),
    destinationURL: 'https://www.buyma.com/?af=987',
  },
  {
    id: 1,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-1.png'),
    destinationURL: 'https://fril.jp/',
  },
  {
    id: 2,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-2.png'),
    destinationURL:
      'https://www.dot-st.com/?utm_source=paidy&utm_medium=lp&utm_campaign=20231120',
  },
  {
    id: 3,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-3.png'),
    destinationURL: 'https://yz-store.com/',
  },
  {
    id: 4,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-4.png'),
    destinationURL:
      'https://www.magaseek.com/top/index/tp_1?utm_medium=other&utm_source=other_link&utm_campaign=paidy_cpn',
  },
  {
    id: 5,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-5.png'),
    destinationURL: 'http://s.runway-ch.jp/rc_231120_paidy',
  },
  {
    id: 6,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-6.png'),
    destinationURL: 'https://www.neweracap.jp/',
  },
  {
    id: 7,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-7.png'),
    destinationURL: 'https://www.atmos-tokyo.com/',
  },
  {
    id: 8,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-8.png'),
    destinationURL: 'https://www.urban-research.jp/',
  },
  {
    id: 9,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-9.png'),
    destinationURL: 'https://dreamvs.jp/',
  },
  {
    id: 10,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-10.png'),
    destinationURL:
      'https://gladd.jp/?t=women&pcode=paidy_benefit&utm_source=paidy&utm_medium=benefit&utm_campaign=benefit_paidy_cp23winter&utm_content=benefit_paidy',
  },
  {
    id: 11,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-11.png'),
    destinationURL: 'https://shop.newbalance.jp/shop/default.aspx',
  },
  {
    id: 12,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-12.png'),
    destinationURL: 'https://www.ralphlauren.co.jp/',
  },
  {
    id: 13,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-13.png'),
    destinationURL: 'https://wego.jp/',
  },
  {
    id: 14,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-14.png'),
    destinationURL:
      'https://www.sixty-percent.com/?utm_source=paidy&utm_medium=referral&utm_campaign=november_campaign',
  },
  {
    id: 15,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-15.png'),
    destinationURL: 'https://bonbon92.com/',
  },
  {
    id: 16,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-16.png'),
    destinationURL: 'https://shop.aimerfeel.jp/shop/default.aspx',
  },
  {
    id: 17,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-17.png'),
    destinationURL:
      'https://www.samantha.co.jp/shop/default.aspx?utm_source=paidy&utm_medium=lp&utm_campaign=All_cb2312',
  },
  {
    id: 18,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-18.png'),
    destinationURL: 'https://esica.shop/',
  },
  {
    id: 19,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-19.png'),
    destinationURL: 'https://cohina.net/?utm_source=paidy&utm_medium=referral',
  },
  {
    id: 20,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-20.png'),
    destinationURL: 'https://edwin-mall.jp',
  },
  {
    id: 21,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-21.png'),
    destinationURL:
      'https://aeonretail.com/?utm_source=paidy&utm_medium=website&utm_campaign=b_hatu_202312_',
  },
  {
    id: 22,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-22.png'),
    destinationURL: 'https://www.underarmour.co.jp/top/CSfTop.jsp',
  },
  {
    id: 23,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-23.png'),
    destinationURL: 'https://nairodept.com',
  },
  {
    id: 24,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-24.png'),
    destinationURL: 'https://www.shipsltd.co.jp/',
  },
  {
    id: 25,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-25.png'),
    destinationURL: 'https://store.rili.tokyo/',
  },
  {
    id: 26,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-26.png'),
    destinationURL: 'https://punyus.jp/',
  },
  {
    id: 27,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-27.png'),
    destinationURL: 'https://www.tu-hacci.co.jp',
  },
  {
    id: 28,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-28.png'),
    destinationURL: 'https://joggo.jp/',
  },
  {
    id: 29,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-29.png'),
    destinationURL: 'http://www.artidaoud.com/',
  },
  {
    id: 30,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-30.png'),
    destinationURL: 'https://www.oshmans-online.jp/shop/default.aspx',
  },
  {
    id: 31,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-31.png'),
    destinationURL:
      'https://www.burnedestrose.com/shop/default.aspx?utm_source=paidy&utm_medium=lp&utm_campaign=paidy_cashback&utm_id=221115',
  },
  {
    id: 32,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-32.png'),
    destinationURL: 'https://casio.link/3rV3Wcs',
  },
  {
    id: 33,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-33.png'),
    destinationURL:
      'https://online.and-chouette.jp/shop/default.aspx?utm_source=paidy&utm_medium=lp&utm_campaign=All_cb2312',
  },
  {
    id: 34,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-34.png'),
    destinationURL:
      'https://www.axes-net.com/?utm_source=paidy&utm_medium=referral&utm_campaign=paidycamp2311',
  },
  {
    id: 35,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-35.png'),
    destinationURL: 'https://outlet.newbalance.jp/shop/',
  },
  {
    id: 36,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-36.png'),
    destinationURL: 'https://morecon.jp/',
  },
  {
    id: 37,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-37.png'),
    destinationURL: 'https://francfranc.com/',
  },
  {
    id: 38,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-38.png'),
    destinationURL:
      'https://greenfunding.jp/?utm_source=Paidy&utm_medium=GREENFUNDING&utm_campaign=Paidy-2311',
  },
  {
    id: 39,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-39.png'),
    destinationURL: 'https://dazzystore.com',
  },
  {
    id: 40,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-40.png'),
    destinationURL:
      'https://d-rw.com/?gclid=Cj0KCQiAorKfBhC0ARIsAHDzslvaJFCZphITzFzN6YF836n0bBGsICzFMuAbNOiseWQE9V7_Q2Qs_24aAuGdEALw_wcB',
  },
  {
    id: 41,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-41.png'),
    destinationURL: 'https://247lingerie.co/',
  },
  {
    id: 42,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-42.png'),
    destinationURL: 'https://www.info.narabuzz.com/',
  },
  {
    id: 43,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-43.png'),
    destinationURL: 'https://morecon.jp/',
  },
  {
    id: 44,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-44.png'),
    destinationURL: 'https://www.buyma.com/?af=987',
  },
  {
    id: 45,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-45.png'),
    destinationURL:
      'https://www.magaseek.com/top/index/tp_1?utm_medium=other&utm_source=other_link&utm_campaign=paidy_cpn',
  },
  {
    id: 46,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-46.png'),
    destinationURL:
      'https://gladd.jp/?t=women&pcode=paidy_benefit&utm_source=paidy&utm_medium=benefit&utm_campaign=benefit_paidy_cp23winter&utm_content=benefit_paidy',
  },
  {
    id: 47,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-47.png'),
    destinationURL: 'https://www.hoshinoresorts.com/',
  },
  {
    id: 48,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-48.png'),
    destinationURL: 'https://www.asoview.com/',
  },
  {
    id: 49,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-49.png'),
    destinationURL: 'https://www.sakuratravel.jp/',
  },
  {
    id: 50,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-50.png'),
    destinationURL: 'https://www.airtrip.jp/',
  },
  {
    id: 51,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-51.png'),
    destinationURL: 'https://travelist.jp/',
  },
  {
    id: 52,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-52.png'),
    destinationURL: 'https://vipliner.biz/',
  },
  {
    id: 53,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-53.png'),
    destinationURL: 'https://dwango-ticket.jp/',
  },
  {
    id: 54,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-54.png'),
    destinationURL: 'https://activityjapan.com/',
  },
  {
    id: 55,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-55.png'),
    destinationURL: 'https://travel.willer.co.jp/?mid=1458',
  },
  {
    id: 56,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-56.png'),
    destinationURL: 'https://www.kosokubus.com/?ad=PDY',
  },
  {
    id: 57,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-57.png'),
    destinationURL: 'https://www.dmm.com/',
  },
  {
    id: 58,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-58.png'),
    destinationURL: 'https://shop.hololivepro.com/',
  },
  {
    id: 59,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-59.png'),
    destinationURL: 'https://jp.17.live/',
  },
  {
    id: 60,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-60.png'),
    destinationURL: 'https://furyu-hm.com',
  },
  {
    id: 61,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-61.png'),
    destinationURL: 'https://p-bandai.jp/',
  },
  {
    id: 62,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-62.png'),
    destinationURL: 'https://www.dlsite.com/index.html',
  },
  {
    id: 63,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-63.png'),
    destinationURL: 'https://www.mbga.jp/',
  },
  {
    id: 64,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-64.png'),
    destinationURL: 'https://www.nicovideo.jp/?from=paidy',
  },
  {
    id: 65,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-65.png'),
    destinationURL: 'https://gold.razer.com/jp/ja',
  },
  {
    id: 66,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-66.png'),
    destinationURL: 'https://www.biccamera.com/bc/main/',
  },
  {
    id: 67,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-67.png'),
    destinationURL: 'https://www.kojima.net/ec/index.html',
  },
  {
    id: 68,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-68.png'),
    destinationURL: 'https://order.mandarake.co.jp/',
  },
  {
    id: 69,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-69.png'),
    destinationURL: 'https://www.torecolo.jp/shop/default.aspx',
  },
  {
    id: 70,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-70.png'),
    destinationURL: 'https://www.soundhouse.co.jp/',
  },
  {
    id: 71,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-71.png'),
    destinationURL: 'https://www.biccamera.com/bc/main/',
  },
  {
    id: 72,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-72.png'),
    destinationURL: 'https://rasik.style/',
  },
  {
    id: 73,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-73.png'),
    destinationURL: 'https://francfranc.com/',
  },
  {
    id: 74,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-74.png'),
    destinationURL: 'https://www.kojima.net/ec/index.html',
  },
  {
    id: 75,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-75.png'),
    destinationURL: 'https://www.yamada-denkiweb.com/',
  },
  {
    id: 76,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-76.png'),
    destinationURL: 'http://shop.tsukumo.co.jp/',
  },
  {
    id: 77,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-77.png'),
    destinationURL: 'https://emma-sleep-japan.com',
  },
  {
    id: 78,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-78.png'),
    destinationURL: 'https://www.irisplaza.co.jp/index.php?KB=paidy',
  },
  {
    id: 79,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-79.png'),
    destinationURL:
      'https://greenfunding.jp/?utm_source=Paidy&utm_medium=GREENFUNDING&utm_campaign=Paidy-2311',
  },
  {
    id: 80,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-80.png'),
    destinationURL: 'https://www.samsung.com/jp/offer/',
  },
  {
    id: 81,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-81.png'),
    destinationURL: 'https://fril.jp/',
  },
  {
    id: 82,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-82.png'),
    destinationURL: 'https://www.urban-research.jp/',
  },
  {
    id: 83,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-83.png'),
    destinationURL:
      'https://www.dot-st.com/?utm_source=paidy&utm_medium=lp&utm_campaign=20231120',
  },
  {
    id: 84,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-84.png'),
    destinationURL:
      'https://aeonretail.com/?utm_source=paidy&utm_medium=website&utm_campaign=b_hatu_202312_',
  },
  {
    id: 85,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-85.png'),
    destinationURL: 'https://casio.link/3rV3Wcs',
  },
  {
    id: 86,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-86.png'),
    destinationURL:
      'https://kurand.jp/?utm_source=kurand&utm_medium=pr_ext_paidy&utm_campaign=0134___',
  },
  {
    id: 87,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-87.png'),
    destinationURL: 'https://fujingaho.ringbell.co.jp/shop/',
  },
  {
    id: 88,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-88.png'),
    destinationURL:
      'https://www.enoteca.co.jp/?utm_source=Paidy&utm_medium=Referral&utm_campaign=kangensai-202311',
  },
  {
    id: 89,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-89.png'),
    destinationURL: 'https://activityjapan.com/',
  },
  {
    id: 90,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-90.png'),
    destinationURL: 'https://order.mandarake.co.jp/',
  },
  {
    id: 91,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-91.png'),
    destinationURL: 'https://www.autoway.jp/',
  },
  {
    id: 92,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-92.png'),
    destinationURL: 'https://shop.hololivepro.com/',
  },
  {
    id: 93,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-93.png'),
    destinationURL: 'https://www.soundhouse.co.jp/',
  },
  {
    id: 94,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-94.png'),
    destinationURL: 'https://www.yamada-denkiweb.com/',
  },
  {
    id: 95,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-95.png'),
    destinationURL: 'http://shop.tsukumo.co.jp/',
  },
  {
    id: 96,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-96.png'),
    destinationURL: 'https://www.ralphlauren.co.jp/',
  },
  {
    id: 97,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-97.png'),
    destinationURL: 'https://www.irisplaza.co.jp/index.php?KB=paidy',
  },
  {
    id: 98,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-98.png'),
    destinationURL: 'https://www.samsung.com/jp/offer/',
  },
  {
    id: 99,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-99.png'),
    destinationURL: 'https://www.underarmour.co.jp/top/CSfTop.jsp',
  },
  {
    id: 100,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-100.png'),
    destinationURL: 'https://www.shipsltd.co.jp/',
  },
  {
    id: 101,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-101.png'),
    destinationURL: 'https://p-bandai.jp/',
  },
  {
    id: 102,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-102.png'),
    destinationURL: 'https://www.dlsite.com/index.html',
  },
  {
    id: 103,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-103.png'),
    destinationURL: 'https://edwin-mall.jp',
  },
  {
    id: 104,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-104.png'),
    destinationURL: 'https://www.torecolo.jp/shop/default.aspx',
  },
  {
    id: 105,
    imgLogo: require('src/images/campaign/20231120_lottery/merchants/logos/logo-105.png'),
    destinationURL: 'https://www.info.narabuzz.com/',
  },
];

export const MERCHANT_GROUPED_BY_CATEGORY = [
  {
    categoryValue: 1,
    merchantIds: [...createArray(0, 42)],
  },
  {
    categoryValue: 2,
    merchantIds: createArray(43, 46),
  },
  {
    categoryValue: 3,
    merchantIds: createArray(47, 56),
  },
  {
    categoryValue: 4,
    merchantIds: createArray(57, 69),
  },
  {
    categoryValue: 5,
    merchantIds: createArray(70, 85),
  },
  {
    categoryValue: 6,
    merchantIds: createArray(86, 89),
  },
  {
    categoryValue: 7,
    merchantIds: createArray(90, 105),
  },
];

export const TAGS = [
  { value: 0, label: 'すべて見る' },
  { value: 1, label: 'ファッション' },
  { value: 2, label: 'コスメ・カラコン' },
  { value: 3, label: 'ホテル・バス・航空券・チケット予約' },
  { value: 4, label: 'ゲーム・動画・ライブ配信など' },
  { value: 5, label: '家電・インテリア' },
  { value: 6, label: 'グルメ' },
  { value: 7, label: 'その他' },
];

export const FIXED_MERCHANTS = [];

export const MERCHANTS_GROUPED_BY_FIXED_TAGS = [];

export const FIXES_TAGS = [];
